<template>
	<div class="min-height-screen h-full">
		<transition name="fade" mode="out-in">
			<div
				v-if="isProductLoading"
				class="d-flex align-center justify-center w-full h-full"
				style="position: absolute; top: 0; left: 0"
			>
				<loader />
			</div>
			<v-card v-else class="pb-4 h-full min-height-screen" elevation="0">
				<div
					class="bg-theme py-4 px-3 w-full"
					style="position: sticky; top: 0; left: 0; z-index: 11"
				>
					<a
						:href="`${baseUrl}/products?token=${authToken}`"
						class="text-decoration-none mr-2 d-inline-block"
					>
						<v-icon color="primary">mdi-arrow-left</v-icon>
					</a>
				</div>
				<v-card-title
					class="text-capitalize text-h5 font-weight-bold"
					style="word-break: break-word"
				>
					{{ product.name }}
				</v-card-title>

				<div class="pa-12">
					<v-img
						min-height="170"
						min-width="170"
						max-height="200"
						max-width="200"
						aspect-ratio="16/9"
						contain
						class="mx-auto rounded-2xl"
						:lazy-src="productImageUrl"
						:src="productImageUrl"
					/>
				</div>

				<div class="px-4">
					<div class="d-flex align-center flex-wrap">
						<p class="primary--text font-weight-bold text-lg mb-0 mr-2">
							{{
								doesProductHaveDiscount
									? currencyFormatter.format(product.dealPrice)
									: currencyFormatter.format(product.unitCost)
							}}
						</p>
						<span
							v-if="doesProductHaveDiscount"
							class="text-decoration-line-through text--secondary my-1 text-sm"
							>{{ currencyFormatter.format(product.unitCost) }}</span
						>
					</div>
					<div v-if="doesProductHaveDiscount" class="d-flex align-center flex-wrap">
						<span class="text-uppercase text-sm mr-2 my-1 text--secondary">
							You save:
							{{ currencyFormatter.format(product.savingAmount) }}
						</span>
						<span class="text-base font-weight-bold my-1" style="color: #ff8a00"
							>{{ product.dealPercentage }}% off</span
						>
					</div>

					<div class="d-flex align-center flex-wrap mb-4">
						<div class="flex-grow-1 my-2 mr-2">
							<span class="font-weight-bold mb-0">Quantity: {{ product.unitQty }}</span>
						</div>
						<!-- if has auth token and product is in the cart -->
						<div v-if="hasAuthToken && isProductInTheCart">
							<div class="d-flex justify-end align-center my-2">
								<button
									class="product-btn product-btn-minus"
									@click="decreaseQuantity"
									:disabled="cartProduct.quantity <= 1"
								>
									<v-icon>mdi-minus</v-icon>
								</button>
								<span class="mx-3 font-weight-bold">{{ cartProduct.quantity }}</span>

								<button class="product-btn product-btn-plus" @click="increaseQuantity">
									<v-icon> mdi-plus </v-icon>
								</button>
							</div>
						</div>
					</div>

					<div class="text--secondary mb-4" v-html="product.description"></div>

					<!-- only if has auth token -->
					<div v-if="hasAuthToken" class="sticky px-2 py-4 bg-theme">
						<v-btn
							color="primary"
							class="pa-3 h-auto w-full d-block rounded-small"
							@click="handleAddOrRemoveProductFromCart"
						>
							<span class="d-flex align-center">
								<span class="mr-3">
									<cart-icon />
								</span>
								<strong class="text-capitalize text-h6 font-weight-bold">{{
									isProductInTheCart ? 'Remove from Cart' : 'Add To Cart'
								}}</strong>
							</span>
						</v-btn>
					</div>
				</div>
			</v-card>
		</transition>
	</div>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import CartIcon from '@/components/icons/CartIcon.vue'
import Loader from '@/components/Loader.vue'
import currencyFormatter from '@/utils/currency-formatter'
import { getProductDetails } from '@/services/products'
import { IMAGE_BASE_URL } from '@/constants'
import { SUCCESS } from '@/constants/status-code'

export default {
	name: 'ProductsDetails',
	mixins: [AuthMixin],
	components: {
		CartIcon,
		Loader,
	},
	data() {
		return {
			IMAGE_BASE_URL,
			currencyFormatter,
			isProductLoading: false,
			productId: this.$route.params.id,
			product: null,
		}
	},
	created() {
		this.getProductDetailsById()
	},
	computed: {
		hasAuthToken() {
			return typeof this.authToken === 'string'
		},
		doesProductHaveDiscount() {
			return this.product.dealPrice !== null && this.product.dealPrice > 0
		},
		cartProducts() {
			return this.$store.state.cartProducts
		},
		isProductInTheCart() {
			const productId = parseInt(this.productId, 10)
			// its faster compare to find and filter so
			return this.cartProducts.some((product) => product.id === productId)
		},
		cartProduct() {
			if (!this.isProductInTheCart) return null
			const productId = parseInt(this.productId, 10)
			// here i need cart product item data so
			return this.cartProducts.find((product) => product.id === productId)
		},
		productImageUrl() {
			if (!this.product.imageUrl) return '/placeholder-image.png'

			if (this.product.imageUrl.startsWith('http')) return this.product.imageUrl

			return `${IMAGE_BASE_URL}/${this.product.imageUrl}`
		},
	},
	methods: {
		increaseQuantity() {
			const productId = parseInt(this.productId, 10)
			this.$store.commit('INCREASE_PRODUCT_QUANTITY', productId)
		},
		decreaseQuantity() {
			const productId = parseInt(this.productId, 10)
			this.$store.commit('DECREASE_PRODUCT_QUANTITY', productId)
		},
		addProductToCart() {
			if (this.isProductInTheCart) return
			const productId = parseInt(this.productId, 10)
			const product = { ...this.product, id: productId, quantity: 1 }
			this.$store.commit('ADD_TO_CART_PRODUCTS', product)
		},
		removeProductFromCart() {
			if (!this.isProductInTheCart) return
			const productId = parseInt(this.productId, 10)
			this.$store.commit('REMOVE_FROM_CART_PRODUCTS', productId)
		},
		handleAddOrRemoveProductFromCart() {
			if (!this.isProductInTheCart) {
				this.addProductToCart()
			} else {
				this.removeProductFromCart()
			}
		},
		async getProductDetailsById() {
			this.isProductLoading = true
			try {
				const res = await getProductDetails(this.authToken, { productId: this.productId })
				const { data, statusCode } = res.data

				if (statusCode !== SUCCESS) throw new Error('Error getting product details')
				this.product = data
			} catch (e) {
				console.log(e)
			}
			this.isProductLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
:deep(.rounded-2xl) {
	border-radius: 2.5rem;
}
.save--price {
	font-weight: 400;
	font-size: 20px;
	color: #7d8fab;
}

.sticky {
	position: sticky;
	bottom: 0;
}
</style>
