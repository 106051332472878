import { Api } from '@/services/api'

export const getAllProductsByCategory = async (token, { params = {} }) => {
	// convert object to url query parameter
	const urlQueryParams = new URLSearchParams(params).toString()
	const encodedParams = encodeURI(urlQueryParams) // encode the url to prevent XSS in search filter
	return Api(token).get(`/V1/Product/GetAllProductsByCategory?${encodedParams}`)
}

export const getProductDetails = async (token, { productId = null }) => {
	if (!productId) return undefined

	return Api(token).get(`/V1/Product/GetProductDetail?id=${productId}`)
}

export const getPopularProducts = async (token, { params }) => {
	// convert object to url query parameter
	const urlQueryParams = new URLSearchParams(params).toString()
	const encodedParams = encodeURI(urlQueryParams) // encode the url to prevent XSS in search filter
	return Api(token).get(`/V1/Product/ProductSuggestion?${encodedParams}`)
}

export default undefined
